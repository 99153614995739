import {
    mapGetters
} from "vuex";

export const mixin = {

    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['vToken', 'vUserInfo','vImgUrl','vVerifycode']),
    },
    methods: {
        
    }
}