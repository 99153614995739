import axios from 'axios'
// import { Message, Loading } from 'element-ui'
import { Toast } from 'vant';
import store from '@/store'
import router from "@/router"

const service = axios.create({
	// baseURL: '/nh/ydkf',
	//baseURL: 'https://management.noitom.com.cn/admin2019/nhealth/',
	// baseURL: 'http://192.168.2.177:8082/client',
	baseURL: `${process.env.VUE_APP_SERVER_URL}`,

	timeout: 50000
})

let loading = null

//http request拦截
service.interceptors.request.use(req => {
	// loading = Loading.service({
	// 	background: 'rgba(0, 0, 0, 0.7)'
	// })
	Toast.loading({
		message: '加载中...',
		forbidClick: true,
		duration:Toast
	});
	if (store.state.vToken) {
		req.headers['x-token'] = store.state.vToken
	}
	return req
})

//http response拦截
service.interceptors.response.use(res => {
	Toast.loading().clear();
	if (res.config.url == '/login/captcha-image') {
		store.commit('SET_vVerifycode', res.headers.verifycode)
	} 
	if (res.status === 200) {
		const message = res.data.msg || '未知错误'
		 if (res.data.code === 1 && (res.data.msg === '无效用户' || res.data.msg === '请登录')) {
			store.commit('Logout');
			window.location.href = `${process.env.VUE_APP_CLIENT_URL}login-sms`;

		 }else if (res.data.code !== 0) {
			Toast.fail(message)
			return Promise.reject(new Error(message))
		}
	}
	return res.data
})

export default service