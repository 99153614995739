<template>
  <div class="report-list">
    <div class="head">
      <div>
        <h1>测评报告</h1>
        <p>测评汇总列表</p>
      </div>
      <img src="@/assets/img/icon-report-list.svg" alt="">
    </div>
    <div class="line"></div>
    <template v-if="reportList.length">
      <div class="report" v-for="(item,i) in reportList" :key="i" @click="toReportDetail(item)">
        <img :src="require(`@/assets/img/icon-${item.reportType===0?'knee-joint':'scoliosis'}.svg`)" alt="">
        <div class="report-info">
          <p>{{ item.reportType === 0 ? "膝关节测评" : "脊柱侧弯筛查" }}报告</p>
          <p>{{ item.creatUser }}&nbsp;&nbsp;{{ item.creatDte }}</p>
          <p><span>查看详情</span><i class="el-icon-arrow-right"></i></p>
        </div>
      </div>
    </template>
    <img v-else src="@/assets/img/noData.svg" alt="" class="noData">
  </div>
</template>

<script>
import { authWechat, assessReport } from "@/api/getData"

export default {
  name: 'ReportList',
  components: {},
  data() {
    return {
      reportList: []
    }
  },
  created() {
    if (!this.vToken) {
      this.toweichat()
    } else {
      this.getReportList()
    }
  },
  methods: {
    //获取测评报告列表
    getReportList() {
      assessReport().then(res => {
        if (res.data) {
          this.reportList = res.data
        }
      })
    },
    //跳转到报告详情页
    toReportDetail(item) {
      if (item.reportType === 0) {//膝关节
        this.$router.push({
          path: "/reportDetail",
          query: {
            reportId: item.reportId
          }
        })
      } else if (item.reportType === 1) {//脊柱测量
        this.$router.push({
          path: "/spinescale-report",
          query: {
            reportId: item.reportId
          }
        })
      }
    },
    toweichat() {
      //判断是否是微信浏览器
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        let code = this.GetParam(window.location.href, "code");
        if (code) {
          //这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
          authWechat(code).then(res => {
            var userInfo = {}
            userInfo.name = res.data.name
            userInfo.mobile = res.data.moblie
            userInfo.birthday = res.data.birthday
            userInfo.gender = res.data.gender

            userInfo.wechatId = res.data.wechatId
            userInfo.unionId = res.data.unionId
            userInfo.wechatImgUrl = res.data.wechatImgUrl
            userInfo.wechatNickname = res.data.wechatNickname
            this.$store.commit('SET_vToken', res.data.token)
            this.$store.commit('SET_vUserInfo', userInfo)
            this.$store.commit('SET_vImgUrl', res.data.wechatImgUrl)
            this.getReportList()
          })
        } else {
          //获取当前页面的地址
          let local = `${process.env.VUE_APP_CLIENT_URL}report-list`;//'http://wctest.ng.noitom.com.cn/#/my';
          //调接微信官方生成授权登录的url appid必须填写
          //wx51177af5a0aaad8d
          let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + `${process.env.VUE_APP_WECHAR_APPID}` + "&redirect_uri="
              + encodeURIComponent(local)
              + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
          //这一步是跳转这上方定义的url
          window.location.href = url;
        }
      } else {
        // this.$toast.fail({
        //   message: '请使用微信浏览器',
        //   forbidClick: true,
        //   overlay: true,
        //   duration: 0
        // })
      }
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.report-list {
  padding: 0.16rem 0.16rem 0;
  background-color: #fdfffe;
  overflow: hidden;

  .head {
    height: 1.11rem;
    padding: 0 0.15rem 0 0.24rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.08rem;
    margin-bottom: 0.24rem;
    background: linear-gradient(301deg, rgba(9, 243, 243, 0.06) 36%, rgba(130, 194, 19, 0.01) 133%);

    h1 {
      font-size: 0.3rem;
      color: #24bbd3;
      margin-bottom: 0.16rem;
    }

    p {
      font-size: 0.14rem;
      line-height: 0.18rem;
      color: #2d5a61;
    }

    img {
      width: 0.91rem;
      height: 0.81rem;
    }
  }

  .line {
    height: 0.01rem;
    margin-bottom: 0.24rem;
    background-color: #f5f5f5;
  }

  .report {
    height: 1.38rem;
    display: flex;
    border-radius: 0.08rem;
    margin-bottom: 0.16rem;
    background-color: #fff;
    box-shadow: 0.03rem 0.04rem 0.05rem 0 rgba(255, 255, 255, 0.3), 0 0.03rem 0.05rem 0.03rem rgba(50, 111, 121, 0.05), 0 0 0.12rem 0 rgba(112, 214, 231, 0.08);

    img {
      width: 0.9rem;
      margin: 0.24rem;
    }

    .report-info {
      margin-top: 0.29rem;
    }

    p:first-child {
      font-size: 0.18rem;
      color: #333333;
    }

    p:nth-child(2) {
      font-size: 0.14rem;
      margin: 0.13rem 0 0.22rem;
      color: #a8a8a8;
    }

    p:last-child {
      height: 0.24rem;
      display: flex;
      align-items: center;

      span {
        font-size: 0.14rem;
        font-family: Source Han Sans;
        color: rgba(36, 187, 211, 0.9);
        margin-right: 0.05rem;
      }

      .el-icon-arrow-right {
        font-size: 0.16rem;
        color: #24bbd3;
      }
    }
  }

  img.noData {
    width: auto;
    height: 2.33rem;
    position: fixed;
    top: 2.79rem;
    left: 0.99rem;
  }
}
</style>