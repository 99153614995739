import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStore,
  getStore,
  clearStore
} from '@/utils/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vToken: getStore({
      name: 'vToken'
    }) || '',
    vUserInfo: getStore({
      name: 'vUserInfo'
    }) || '',
    vImgUrl: getStore({
      name: 'vImgUrl'
    }) || '',
    vVerifycode: getStore({
      name: 'vVerifycode'
    }) || ''
  },
  mutations: {
    SET_vVerifycode: (state, vVerifycode) => {
      state.vVerifycode = vVerifycode;
      setStore({
        name: 'vVerifycode',
        content: state.vVerifycode
      })
    },
    SET_vToken: (state, vToken) => {
      state.vToken = vToken;
      setStore({
        name: 'vToken',
        content: state.vToken
      })
    },
    SET_vUserInfo: (state, vUserInfo) => {
      state.vUserInfo = vUserInfo;
      setStore({
        name: 'vUserInfo',
        content: state.vUserInfo
      })
    },
    SET_vImgUrl: (state, vImgUrl) => {
      state.vImgUrl = vImgUrl;
      setStore({
        name: 'vImgUrl',
        content: state.vImgUrl
      })
    },
    Logout: (state) => {
      state.vToken = state.vUserInfo = ''
      clearStore();
    },
  },
  actions: {
  },
  getters: {
    vToken: state => state.vToken,
    vVerifycode: state => state.vVerifycode,
    vUserInfo: state => state.vUserInfo,
    vImgUrl: state => state.vImgUrl
  },
  modules: {
  }
})
