import Vue from 'vue';
import VueRouter from 'vue-router';
import Appver from "../views/Appver/Appver";	//版本
import LoginSms from '../views/Login/LoginSms.vue'	//登录
// import store from '../store'
import Home from "@/views/H5/Home"
import ReportList from "@/views/ReportList/ReportList"

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '小诺肌骨'
		}
	},
	{
		path: '/product-introduction',
		name: 'ProductIntroduction',
		component: () => import('@/views/H5/ProductIntroduction'),
		meta: {
			title: '小诺肌骨'
		}
	},
	{
		path: '/company-profile',
		name: 'CompanyProfile',
		component: () => import('@/views/H5/CompanyProfile'),
		meta: {
			title: '公司介绍'
		}
	},
	{
		path: '/book',
		name: 'Book',
		component: () => import('@/views/Home/Home'),
		meta: {
			title: '预约测评'  //免责授权
		}
	},
	{
		path: '/questionnaire',
		name: 'Questionnaire',
		component: () => import('@/views/Questionnaire/Questionnaire'),
		meta: {
			title: '预约测评'   //问卷
		}
	},
	{
		path: '/bookingStatus',
		name: 'BookingStatus',
		component: () => import('@/views/Questionnaire/BookingStatus'),
		meta: {
			title: '预约测评'  //预约结果
		}
	},
	{
		path: '/my',
		name: 'My',
		component: () => import('@/views/My/My'),
		meta: {
			title: '我的健康'
		}
	},
	{
		path: '/wechatPay',
		name: 'wechatPay',
		component: () => import('@/views/Questionnaire/wechatPay'),
		meta: {
			title: '微信支付'
		}
	},
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		component: () => import('@/views/Others/Disclaimer'),
		meta: {
			title: '免责声明'
		}
	},
	{
		path: '/card',
		name: 'Card',
		component: () => import('@/views/Others/Card'),
		meta: {
			title: '体验卡'
		}
	},
	{
		path: '/report-list',
		name: 'ReportList',
		component: () => import('@/views/ReportList/ReportList'),
		meta: {
			title: '健康测评'
		}
	},
	{
		path: '/reportDetail',
		name: 'ReportDetail',
		component: () => import('@/views/Others/ReportDetail'),
		meta: {
			title: '测评报告'
		}
	},
	{
		path: '/spinescale-report',
		name: 'SpinescaleReport',
		component: () => import('@/views/Spinescale/Report'),
		meta: {
			title: '测评报告'
		}
	},
	{
		path: '/instruction',
		name: 'Instruction',
		component: () => import('@/views/Others/Instruction'),
		meta: {
			title: '小诺肌骨使用指南'
		}
	},
	{
		path: '/login-sms',
		name: 'LoginSms',
		component: LoginSms,
		meta: {
			title: '短信登录'
		}
	},
	{
		path: '/appver',
		name: 'Appver',
		component: Appver,
		meta: {
			title: 'Appver'
		}
	}
];

const router = new VueRouter({
	// mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
});

//页面打开之前
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
	// if (to.meta.requireAuth) {
	// 	if (store.state.vToken) {
	// 		next()
	// 	} else {
	// 		next('/login-pwd')
	// 	}
	// } else {
	// 	if (to.path === '/login-pwd' && store.state.vToken) {
	// 		next(from.path)
	// 	} else {
	// 		next()
	// 	}
	// }
});

// 微信授权插件初始化
// Vue.use(wechatPlugin, {
// 	router, // 路由实例对象
// 	appid: 'wx51177af5a0aaad8d', // 您的微信appid
// 	responseType: 'code', // 返回类型，请填写code
// 	scope: 'snsapi_userinfo', // 应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且，即使在未关注的情况下，只要用户授权，也能获取其信息）
// 	redirectUri: 'http://wctest.ng.noitom.com.cn/', //微信回调地址
// 	getCodeCallback(next, code) {
// 	},
// })


//router path重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default router;