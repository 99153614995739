<template>
  <header class="pc">
<!--    <div class="mobile">-->
<!--      <div>-->
<!--        <a href="/" class="logo-wrap">-->
<!--          <img src="@/assets/img/mobile/logo.svg" alt="" class="logo">-->
<!--          <span>小诺肌骨</span>-->
<!--        </a>-->
<!--        <van-icon v-show="showNav" name="cross" @click="changeShowNav('')"/>-->
<!--        <img v-show="!showNav" src="@/assets/img/mobile/icon-menu.svg" alt="" class="icon-menu"-->
<!--             @click="changeShowNav('hidden')">-->
<!--      </div>-->
<!--      <nav :class="{show: showNav}">-->
<!--        <ul>-->
<!--          <li><a href="/">首页</a></li>-->
<!--          <li><a :href="localUrl+'company-profile'" @click="toUrl('company-profile')">公司介绍</a></li>-->
<!--          <li><a :href="localUrl+'product-introduction'" @click="toUrl('product-introduction')">小诺肌骨</a></li>-->
<!--          <li><a :href="localUrl+'report-list'">测评报告</a></li>-->
<!--        </ul>-->
<!--      </nav>-->
<!--    </div>-->
    <div class="pc">
      <a href="/" class="logo"><img src="@/assets/img/logo.svg" alt="">小诺肌骨</a>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      // showNav: false,
      // localUrl: ''
    }
  },
  // methods: {
  //   changeShowNav(val) {
  //     document.body.style.overflow = val
  //     this.showNav = !this.showNav
  //   },
  //   toUrl(url) {
  //     if (this.$route.path.includes(url)) {
  //       location.reload()
  //     } else {
  //       location.href = this.localUrl + url
  //     }
  //   },
  //   toBook() {
  //     document.body.style.overflow = ''
  //   },
  // },
  // mounted() {
  //   document.body.style.overflow = ''
  //   this.localUrl = `${process.env.VUE_APP_CLIENT_URL}`
  // },
  // beforeDestroy() {
  //   document.body.style.overflow = ''
  // }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 666;

  .pc {
    height: 0.72rem;
    background-color: #00aebb;
    box-shadow: 0 0 0.04rem 0 rgba(0, 0, 0, 0.08);

    .logo {
      width: fit-content;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 0.18rem;
      font-weight: 500;
      margin-left: 1.2rem;
      color: #ffffff;
      
      img {
        width: 0.36rem;
        margin-right: 0.12rem;
      }
    }
  }

  //.mobile {
  //  > div {
  //    height: 0.48rem;
  //    padding: 0 0.18rem;
  //    display: flex;
  //    align-items: center;
  //    justify-content: space-between;
  //    background-color: #fff;
  //
  //    .logo-wrap {
  //      display: flex;
  //      align-items: center;
  //      font-size: 0.12rem;
  //      font-weight: 500;
  //
  //      .logo {
  //        width: 0.24rem;
  //        margin-right: 0.06rem;
  //      }
  //    }
  //
  //    .van-icon-cross {
  //      font-size: 0.22rem;
  //      color: #00aebb;
  //      transition: 0.3s;
  //    }
  //
  //    .icon-menu {
  //      width: 0.18rem;
  //      transition: 0.3s;
  //    }
  //  }
  //
  //  nav {
  //    width: 100%;
  //    height: calc(100vh - 0.48rem);
  //    position: fixed;
  //    top: 0.48rem;
  //    left: 0;
  //    opacity: 0;
  //    overflow: hidden;
  //    pointer-events: none;
  //    transition: all .3s;
  //    background-color: #fff;
  //
  //    ul {
  //      padding: 0 0.24rem;
  //
  //      li {
  //        font-size: 0.16rem;
  //        color: #1a1a1a;
  //        border-bottom: 0.01rem solid #eeeeee;
  //
  //        a {
  //          display: inline-block;
  //          width: 100%;
  //          line-height: 0.53rem;
  //          padding-left: 0.05rem;
  //        }
  //      }
  //    }
  //  }
  //
  //  nav.show {
  //    opacity: 1;
  //    overflow-y: auto;
  //    pointer-events: all;
  //  }
  //}
}
</style>