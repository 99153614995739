<template>
  <footer>
    <div>
      <h3 class="mobile">联系我们</h3>
      <img src="@/assets/img/mobile/QR_code.svg" alt="">
      <p>
        详细信息请关注微信公众号<br>寻求合作请发送邮件至<br><a href="mailto:health@noitom.com">health@noitom.com</a><br>
        <a href="https://beian.miit.gov.cn/" rel="nofollow" title="网站备案号：京ICP备13027166号">网站备案号：京ICP备13027166号</a>
      </p>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {
  padding: 0.36rem 0 0.28rem;
  text-align: center;
  color: #fff;
  background-color: #00aebb;
  overflow: hidden;

  h3 {
    line-height: 0.24rem;
    font-size: 0.18rem;
    margin-bottom: 0.24rem;
  }

  img {
    display: block;
    width: 1.44rem;
    margin: 0 auto 0.24rem;
  }

  p {
    font-size: 0.12rem;
    line-height: 0.2rem;
  }

  a {
    color: #ffffff;
  }
}
</style>