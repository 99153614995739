<template>
  <div class="Home">
    <Header/>
    <div class="banner-wrap">
      <img src="@/assets/img/mobile/home_banner.webp" alt="" class="banner mobile">
      <img src="@/assets/img/home_banner.jpg" alt="" class="banner pc">
      <div class="banner-content">
        <div class="mobile"><img src="@/assets/img/mobile/logo.svg" alt=""><span>小诺肌骨</span></div>
        <h2>疗愈在指尖</h2>
        <p>您的私人运动康复专家</p>
      </div>
    </div>
    <div class="health">
      <div class="title-wrap">
        <img src="@/assets/img/product.webp" alt="" class="pc">
        <div>
          <h3>肌肉骨骼疾病负担，影响广泛</h3>
          <p>对全球超过350种疾病负担调查显示，肌肉骨骼系统疾病负担在过去30年显著上升，成为全球致残和生产力损失的最主要原因。</p>
          <div class="data-box">
            <div>
              <div class="data">54<span>%</span></div>
              <p>成年人存在<br>肌肉骨骼系统疾病</p>
            </div>
            <div>
              <div class="data">80<span>%</span></div>
              <p>人群没有接受<br>最好效果的干预</p>
            </div>
            <div>
              <div class="data">67<span>%</span></div>
              <p>肌骨问题手术可以<br>通过保守治疗避免</p>
            </div>
          </div>
        </div>
      </div>
      <h3 style="margin-bottom: 0.36rem;">科学定制康复方案，用心服务健康生活</h3>
      <div class="health-content-box">
        <div class="health-content">
          <img src="@/assets/img/mobile/health1.webp" alt="" class="mobile">
          <img src="@/assets/img/health1.webp" alt="" class="pc">
          <div>个性处方</div>
          <p>基于循证医学<span class="mobile">，</span><br class="pc">制定最佳个性运动处方</p>
        </div>
        <div class="health-content">
          <img src="@/assets/img/mobile/health2.webp" alt="" style="width: 1.92rem;margin: 0 0 0.12rem 0.975rem;" class="mobile">
          <img src="@/assets/img/health2.webp" alt="" class="pc">
          <div>居家监测</div>
          <p>佩戴智能传感器<span class="mobile">，</span><br class="pc">实现康复动作实时监测</p>
        </div>
        <div class="health-content">
          <img src="@/assets/img/mobile/health3.webp" alt="" class="mobile">
          <img src="@/assets/img/health3.webp" alt="" class="pc">
          <div>科普教育</div>
          <p>学习健康知识<span class="mobile">，</span><br class="pc">做自己的健康专家</p>
        </div>
        <div class="health-content">
          <img src="@/assets/img/mobile/health4.webp" alt="" class="mobile">
          <img src="@/assets/img/health4.webp" alt="" class="pc">
          <div>专业团队</div>
          <p>医生专家与康复师<span class="mobile">，</span><br class="pc">提供专业定制服务</p>
        </div>
      </div>
    </div>
    <div class="course">
      <h3>康复课程</h3>
      <van-swipe class="my-swipe mobile" :autoplay="3000" indicator-color="#00aebb">
        <van-swipe-item>
          <img src="@/assets/img/mobile/course1.webp" alt="">
          <p>脊柱侧弯康复</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/img/mobile/course2.webp" alt="">
          <p>膝盖慢性疼痛</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/img/mobile/course3.webp" alt="">
          <p>骨科术后康复</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/img/mobile/course4.webp" alt="">
          <p>体姿体态矫正</p>
        </van-swipe-item>
      </van-swipe>
      <img src="@/assets/img/course.webp" alt="" class="pc">
    </div>
    <div class="usage-process">
      <h3>产品使用流程</h3>
      <p>小诺肌骨产品，包括微信小程序、智能传感器、配套康复器材、后台管理系统等，为用户提供信息建档、智能测评、个性处方以及数据监测等服务。</p>
      <img src="@/assets/img/mobile/flowchart.webp" alt="" class="mobile">
      <img src="@/assets/img/flowchart.webp" alt="" class="pc">
    </div>
    <div class="sports-rehabilitation">
      <h3>运动康复可以明显缓解疼痛<br class="mobile"><span class="pc">，</span>改善患肢功能和提高患者生活质量</h3>
      <div>
        <img src="@/assets/img/mobile/double_quotation_marks1.svg" alt="" class="left">
        <p>《2022年美国物理治疗协会国际和双语临床实践指南：远程康复治疗》：对于远程康复使用建议为B级。就患者接受度和满意度而言，远程康复与面对面的物理治疗效果相当，甚至更好。</p>
        <img src="@/assets/img/mobile/double_quotation_marks2.svg" alt="" class="right">
        <img src="@/assets/img/mobile/double_quotation_marks1.svg" alt="" class="left">
        <p>运动处方中国专家共识（2023）：运动处方实施过程中的随访，建议每月至少1次，鼓励通过远程化信息化方式，进行智能评估以及远程干预，通过远程动态监测及时调整运动处方。</p>
        <img src="@/assets/img/mobile/double_quotation_marks2.svg" alt="" class="right">
        <img src="@/assets/img/mobile/double_quotation_marks1.svg" alt="" class="left">
        <p style="padding-right: 0.12rem;">《The effectiveness of internet-based telerehabilitation among patients after total
          joint arthroplasty: A systematic review and
          meta-analysis of randomised controlled trials.》荟萃分析显示，基于互联网的远程康复模式与传统康复模式相比，在疼痛、关节活动度、身体功能、健康生命质量、满意度和心理健康方面效果相当。</p>
        <img src="@/assets/img/mobile/double_quotation_marks2.svg" alt="" class="right">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

export default {
  name: 'Home',
  components: { Header, Footer },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
.Home {
  p {
    font-size: 0.12rem;
    line-height: 0.18rem;
    color: #1a1a1a;
  }

  h3 {
    font-size: 0.18rem;
    text-align: center;
    line-height: 0.24rem;
    margin-bottom: 0.24rem;
    color: #00aebb;
  }

  .banner-wrap {
    position: relative;
    color: #ffffff;

    .banner {
      display: block;
      width: 100%;
      height: 5.4rem;
    }

    .banner-content {
      position: absolute;
      bottom: 0.54rem;
      left: 0.3rem;

      div {
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        font-weight: 500;

        img {
          width: 0.24rem;
          margin-right: 0.06rem;
        }
      }

      h2 {
        font-size: 0.24rem;
        line-height: 1;
        margin: 0.12rem 0 0.06rem;
      }

      p {
        color: #ffffff;
      }
    }
  }

  .health {
    padding: 0.6rem 0 0.36rem;

    .title-wrap > div > p {
      padding: 0 0.3rem 0.24rem;
    }

    .data-box {
      padding: 0 0.25rem;
      margin-bottom: 0.72rem;
      display: flex;
      justify-content: space-between;

      > div {
        text-align: center;
      }

      .data {
        width: 0.84rem;
        height: 0.44rem;
        line-height: 0.44rem;
        font-size: 0.3rem;
        font-weight: 900;
        margin-bottom: 0.06rem;
        border-radius: 0.06rem;
        color: #ffffff;
        background-color: #00aebb;

        span {
          font-size: 0.18rem;
        }
      }

      p {
        font-size: 0.1rem;
        line-height: 0.12rem;
      }
    }

    .health-content {
      text-align: center;
      margin-bottom: 0.36rem;

      img {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        margin: 0 auto 0.12rem;
      }

      div {
        font-size: 0.14rem;
        font-weight: 700;
        color: #00aebb;
        margin-bottom: 0.03rem;
      }

      div, p {
        line-height: 0.2rem;
      }
    }
  }

  .course {
    padding: 0.36rem 0.36rem 0.12rem;
    background-color: #f7f7f7;

    .my-swipe {
      height: 2.88rem;

      p {
        text-align: center;
      }

      ::v-deep(.van-swipe__indicators) {
        bottom: 0;

        .van-swipe__indicator {
          width: 0.06rem;
          height: 0.06rem;
          opacity: 1;
          background-color: #d8d8d8;
        }

        .van-swipe__indicator:not(:last-child) {
          margin-right: 0.06rem;
        }
      }
    }

    img {
      display: block;
      width: 3.03rem;
      height: 2.22rem;
      margin-bottom: 0.12rem;
    }
  }

  .usage-process {
    padding: 0.72rem 0;

    p {
      padding: 0 30px;
      margin-bottom: 0.24rem;
    }

    img {
      display: block;
      width: 3.36rem;
      margin: 0 auto;
    }
  }

  .sports-rehabilitation {
    margin-bottom: 0.36rem;
    overflow: hidden;

    h3 {
      line-height: 0.24rem;
      margin-bottom: 0.36rem;
    }
    
    div {
      padding: 0 0.3rem;
    }

    img {
      display: block;
      width: 0.24rem;
    }

    .left {
      margin-bottom: 0.12rem;
    }

    .right {
      margin: 0 0 0.36rem 2.91rem;
    }

    p {
      padding: 0 0.24rem;
      margin-bottom: 0.12rem;
    }
  }
}
</style>