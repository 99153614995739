<template>
  <div class="login">
    <img src="https://cloudcdn.noitom.com.cn/noitom2/wxapp/register_bg.svg" alt="" class="login-bg">
    <div class="logo-wrap">
      <img src="https://cloudcdn.noitom.com.cn/noitom2/wxapp/logo.svg	" alt="">
      <div>小诺肌骨</div>
    </div>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="手机号："></el-input>
      </el-form-item>
      <el-form-item prop="captchaCode" class="captchaBox">
        <el-input v-model="ruleForm.captchaCode" placeholder="图形验证码："></el-input>
        <img :src="'data:image/jpeg;base64,'+imgSrc" @click="refreshImg" />
      </el-form-item>
      <el-form-item prop="smsCode" class="captchaBox">
        <el-input v-model="ruleForm.smsCode" placeholder="验证码："></el-input>
        <el-button @click="getCode" :disabled="timerTf" v-if="!timerTf">发送</el-button>
        <el-button v-else disabled>{{countDownNum}} S</el-button>
      </el-form-item>
      <el-form-item prop="disclaimer">
        <el-checkbox-group v-model="ruleForm.disclaimer">
          <el-checkbox>我已阅读并同意<router-link to="/disclaimer">免责条款</router-link>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
  </div>
</template>
<script>
import { getCaptchaImage,sendLoginSms,LoginSms,authWechat } from "@/api/getData"

export default {
  name: "LoginSms",
  data() {
    return {
      timer: null,
      timerTf: false,
      countDownNum: 120,
      imgSrc:'',
      ruleForm: {
        mobile: '',
        captchaCode: '',
        smsCode: '',
        disclaimer: []
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        disclaimer: [
          { type: 'array', required: true, message: '请勾选免责条款', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    //图形验证码
    getCaptchaImage() {
      getCaptchaImage().then(res => {
        this.imgSrc = res.data
      })
    },
    //刷新图形验证码
    refreshImg() {
      this.getCaptchaImage()
    },
    //获取短信验证码
    getCode() {
      this.$refs.ruleForm.validateField(['captchaCode'], (val) => {
        if (!val) {
          //   return true;
          //sendRegisterSms
          let data = {
            captchaKey: this.vVerifycode,
            captchaCode: this.ruleForm.captchaCode,
            mobile: this.ruleForm.mobile
          }
          sendLoginSms(data).then(res => {
            this.timerTf = true
            this.countDownNum = 120
            this.timer = setInterval(() => {
              this.countDownNum--
              if (this.countDownNum <= 0) {
                this.timerTf = false
                clearInterval(this.timer)
              }
              }, 1000)
            })
        } else {
          return false;
        }
      })
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            smsCode: this.ruleForm.smsCode,
            wechatId: this.vUserInfo.wechatId,
            unionId: this.vUserInfo.unionId,
            wechatImgUrl: this.vUserInfo.wechatImgUrl,
            wechatNickname: this.vUserInfo.wechatNickname
          }
          LoginSms(data).then(res => {
            this.$message.success('登录成功')
             var userInfo = {}
            userInfo.name = res.data.name
            this.$store.commit('SET_vToken', res.data.token)
            this.$store.commit('SET_vUserInfo', userInfo)
            // if(res.data.isSchedule){
            //   this.$router.push({
            //     path: "/card",
            //     query: {
            //       scheduleId: res.data.scheduleId
            //     }
            //   })
            // }else{
            //    this.$router.replace('/report-list')
            // }
            this.$router.replace('/report-list')
          })
        } else {
          return false;
        }
      });
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
    toweichat() {
      //测试登录
      // var userInfo = {
      //   wechatId:"oVcl76CG6QlqdVZU0qJg_Uok0JSs",
      //   unionId:"oVcl76CG6QlqdVZU0qJg_Uok0JSs"
      // }
      //this.$store.commit('SET_vUserInfo', userInfo)


      if(this.vUserInfo.wechatId || this.vUserInfo.unionId){
        return;
      }
      //判断是否是微信浏览器
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      {
        //判断是否是微信浏览器
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          let code = this.GetParam(window.location.href, "code");
          if (code) {
            //这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
            authWechat(code).then(res => {
              var userInfo = {}                
              userInfo.wechatId =  res.data.wechatId
              userInfo.unionId =  res.data.unionId
              userInfo.wechatImgUrl =  res.data.wechatImgUrl
              userInfo.wechatNickname = res.data.wechatNickname
              if (res.data.token) {
                this.$message.success('登录成功')
                userInfo.name = res.data.name
                this.$store.commit('SET_vToken', res.data.token)
                this.$store.commit('SET_vUserInfo', userInfo)
                // if(res.data.isSchedule){
                //   this.$router.push({
                //     path: "/card",
                //     query: {
                //       scheduleId: res.data.scheduleId
                //     }
                //   })   
                // }else{
                //   this.$router.replace('/report-list')
                // } 
                this.$router.replace('/report-list')                         
              } else {
                this.$store.commit('SET_vUserInfo', userInfo)
              }
            })
          } else {
            //获取当前页面的地址
            let local = `${process.env.VUE_APP_CLIENT_URL}login-sms`;
            //调接微信官方生成授权登录的url appid必须填写
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + `${process.env.VUE_APP_WECHAR_APPID}` + "&redirect_uri="
              + encodeURIComponent(local)
              + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
            //这一步是跳转这上方定义的url
            window.location.href = url;
          }
        } else {
          this.$message.error('请使用微信浏览器')
        }
      }
    },

  },
  mounted() {
    this.toweichat()
    this.getCaptchaImage()
  }
}
</script>

<style lang="scss" scoped>
.login {
  overflow: hidden;

  .login-bg {
    display: block;
    width: 100%;
    margin-bottom: 0.86rem;
  }

  .logo-wrap {
    position: absolute;
    top: 0.6rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.18rem;
    font-weight: 500;
    color: #ffffff;
    text-align: center;

    img {
      width: 1.02rem;
      height: 1.02rem;
      margin-bottom: 0.15rem;
    }
  }

  :deep(.el-form) {
    width: 2.55rem;
    height: 1.79rem;
    box-sizing: border-box;
    padding: 0.2rem;
    font-size: 0.14rem;
    position: absolute;
    top: 2.48rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.2rem;
    box-shadow: 0 0.04rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .el-form-item {
      margin-bottom: 0.2rem;
    }

    .el-input__inner {
      height: 0.33rem;
    }

    .captchaBox .el-form-item__content {
      justify-content: space-between;

      .el-input, .el-input__inner {
        width: 1.45rem;
      }

      img {
        width: 0.64rem;
      }

      .el-button {
        width: 0.64rem;
      }
    }

    .captchaBox + .captchaBox {
      margin-bottom: 0.48rem;
    }
  }

  :deep(.el-checkbox__label) {
    font-size: 0.12rem;
    padding-left: 0.04rem;

    a {
      color: #24BBD3;
    }
  }

  > .el-button {
    display: block;
    width: 2.55rem;
    height: 0.48rem;
    border-radius: 0.4rem;
    font-size: 0.18rem;
    font-weight: 500;
    margin: 0 auto;
  }
}
</style>