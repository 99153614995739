<template>
  <div>
    <h1>Server build info </h1>
    <div class="appver">
        <p>run.host: {{appver['run.host']}}</p>
        <p>run.remote: {{appver['run.remote']}}</p>
        <p>server build.datetime: {{appver['build.datetime']}}</p>
        <p>server build.revision: {{appver['build.revision']}}</p>
    </div>
    <h1>Client build info </h1>
    <div>
      <p>client buildversion: {{h5ver['buildversion']}}</p>
      <p>client buildtime: {{h5ver['buildtime']}}</p>
    </div>
  </div>
</template>

<script>
import { getAppver } from "@/api/getData";
import h5BuildInfo from '../../buildinfo.json'

export default {
   //声明数据源
  data() {
    return {
      appver: {
      },
      h5ver:{
      }
    };
  },
  //定义方法
  methods: {

    getH5Version() {
			this.h5ver = h5BuildInfo
		},

    async getAppverData() {
      try {
        const result = await getAppver();
        console.log('getAppver()=>',result);

        if (result.code == 0) {
          this.appver = result.data
          console.log('appver', this.appver)
        }
      }catch(error){
          console.log(error)
      }
    },

    // async getAppverDataWPTP() {
    //   try {
    //     const result = await getAppverWithPathAndTokenAndParams("abcdefg", "paramV", "tokenV");
    //     console.log('getAppverWithPathAndTokenAndParams()=>',result);
    //     if (result.data.code == 0) {
    //       this.appver = result.data.data
    //     }
    //   }catch(error){
    //       console.log(error)
    //   }
    // },

    // async getAppverDataWT() {
    //   try {
    //     const result = await getAppverWithToken("token1");
    //     console.log('getAppverWithToken()=>',result);
    //     if (result.data.code == 0) {
    //       this.appver = result.data.data
    //     }
    //   }catch(error){
    //       console.log(error)
    //   }
    // },

    // async postDemo1(){
    //   try{
    //     const result = await postDemoParam('playId1', 'groupId1', 'pwd1');
    //     console.log('loginApi=>', result)
    //   }catch(error){
    //       console.log(error)
    //   }
    // },

    // async postDemo2(){
    //   try{
    //     let reqBody = {'playId':'playId2', 'groupId':'groupId2', 'pwd':'pwd2'}
    //     const result = await postDemoBody(reqBody);
    //     console.log('loginApi=>', result)
    //   }catch(error){
    //       console.log(error)
    //   }
    // }

  },
  mounted(){
      //页面渲染完成调用方法获取数据
      this.getAppverData();
      this.getH5Version();
  }
};
</script>

<style lang="scss" scoped></style>